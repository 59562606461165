import { getAnalytics } from "firebase/analytics";
import { User as FirebaseUser } from "firebase/auth";

import "typeface-rubik";
import "@fontsource/ibm-plex-mono";

import logo from "./images/everest_logo.png";

import {
    Authenticator,
    FirebaseCMSApp,
    FirebaseLoginView,
    FirebaseLoginViewProps
} from "@camberi/firecms";
import { firebaseConfig } from "./firebase_config";
import { productsCollection } from "./collections/product_collection";
import { portfoliosCollection } from "./collections/portfolio_collection";
import {
    categoriesCollection,
    printingTypesCollection,
    specificationIconsCollection,
    subcategoriesCollection
} from "./collections/system_collection";
import { textSearchController } from "./text_search";

export default function App() {

    const onFirebaseInit = (config: Object) => {
        getAnalytics();
    };

    const myAuthenticator: Authenticator<FirebaseUser> = async ({ user, authController }) => {

        const sampleUserData = await Promise.resolve({
            roles: ["admin"]
        });

        authController.setExtra(sampleUserData);
        return true;
    };

    return <FirebaseCMSApp
        name={"Nomadic Expo"}
        authentication={myAuthenticator}
        signInOptions={[
            'password'
        ]}
        textSearchController={textSearchController}
        allowSkipLogin={false}
        logo={logo}
        collections={[
            productsCollection,
            portfoliosCollection,
            categoriesCollection,
            subcategoriesCollection,
            printingTypesCollection,
            specificationIconsCollection
        ]}
        firebaseConfig={firebaseConfig}
        onFirebaseInit={onFirebaseInit}
        LoginView={CustomLoginView}
    />;
}

function CustomLoginView(props: FirebaseLoginViewProps) {
    return <FirebaseLoginView
        {...props}
        NoUserComponent={<>This email address isn't valid.</>}
        disableSignupScreen={true}/>;
}
