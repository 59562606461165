import {
    buildCollection, buildProperty, EntityOnSaveProps, toSnakeCase
} from "@camberi/firecms";
import { Configuration, Product } from "../types/product_types";


const configurationsCollection = buildCollection<Partial<Configuration>>({
    name: "Configurations",
    path: "configurations",
    defaultSize: "m",
    singularName: "Configuration",
    callbacks: {
        onPreSave: ({
                        values,
                        previousValues
                    }: EntityOnSaveProps<Partial<Configuration>>) => {
            if (values.order !== previousValues?.order)
                values.shouldUpdateOrder = true;
            return values;
        }
    },
    properties: {
        order: {
            dataType: "number",
            name: "Order",
            defaultValue: 0
        },
        title: {
            name: "Title",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
        description: {
            name: "Description",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    markdown: true
                },
                en: {
                    name: "English",
                    dataType: "string",
                    markdown: true
                }
            }
        },
        image: (entity) => buildProperty({
            name: "Image",
            dataType: "string",
            description: "The dimensions must be 452 x 340 pixels.",
            storage: {
                mediaType: "image",
                storagePath: entity.path,
                acceptedFiles: ["image/*"]
            }
        }),
    }
});

export { configurationsCollection }
