import algoliasearch, { SearchClient } from "algoliasearch";

import {
    FirestoreTextSearchController,
    performAlgoliaTextSearch
} from "@camberi/firecms";

let client: SearchClient | undefined = undefined;
// process is defined for react-scripts builds
if (process.env.REACT_APP_ALGOLIA_APP_ID && process.env.REACT_APP_ALGOLIA_SEARCH_KEY) {
    client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
}

const productsIndex = client && client.initIndex("products");

export const textSearchController: FirestoreTextSearchController =
    ({ path, searchString }) => {
        console.log({ path, searchString, productsIndex });
        if (path === "products")
            return productsIndex && performAlgoliaTextSearch(productsIndex, searchString);
        return undefined;
    };
