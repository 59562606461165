import {
    buildCollection,
    buildProperty,
    EntityOnSaveProps
} from "@camberi/firecms";

import { Portfolio } from "../types/portfolio_types";

const portfoliosCollection = buildCollection<Partial<Portfolio>>({
    path: "portfolios",
    name: "Portfolio",
    group: "Main",
    permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true
    }),
    callbacks: {
        onPreSave: ({
                        values,
                        previousValues
                    }: EntityOnSaveProps<Partial<Portfolio>>) => {
            if (values.order !== previousValues?.order)
                values.shouldUpdateOrder = true;
            return values;
        }
    },
    properties: {
        order: {
            dataType: "number",
            name: "Order",
            defaultValue: 0
        },
        title: {
            name: "Title",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
        image: buildProperty({
            title: "Image",
            dataType: "string",
            storage: {
                mediaType: "image",
                storagePath: "portfolio",
                acceptedFiles: ["image/*"]
            }
        }),
    }
});

export { portfoliosCollection }
