import {
    buildCollection,
    buildProperty,
    EntityOnSaveProps
} from "@camberi/firecms";
import { Format, Model } from "../types/product_types";


const formatsCollection = buildCollection<Partial<Format>>({
    name: "Formats",
    path: "formats",
    defaultSize: "m",
    singularName: "Format",
    callbacks: {
        onPreSave: ({
                        values,
                        previousValues
                    }: EntityOnSaveProps<Partial<Format>>) => {
            if (values.order !== previousValues?.order)
                values.shouldUpdateOrder = true;
            return values;
        }
    },
    properties: {
        order: {
            dataType: "number",
            name: "Order",
            defaultValue: 0
        },
        title: {
            name: "Title",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
        dimension: {
            name: "Dimension",
            dataType: "string",
            description: 'Ex.: 110"x89"x35"',
        },
        image: (entity) => buildProperty({
            name: "Image",
            dataType: "string",
            description: "The dimensions must be 452 x 340 pixels.",
            storage: {
                mediaType: "image",
                storagePath: entity.path,
                acceptedFiles: ["image/*"]
            }
        })
    }
});

export { formatsCollection }
