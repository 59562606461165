import {
    buildCollection,
    EntityOnSaveProps,
    Properties
} from "@camberi/firecms";
import { configurationsCollection } from "./configuration_collection";
import { formatsCollection } from "./formats_collection";
import { modelsCollection } from "./models_collection";
import { accessoriesCollection } from "./accesories_collection";

import { Product } from "../types/product_types";
import { slugify } from "../tools";

const translationProperties = (required: boolean, unique: boolean = false, markdown: boolean = false): Properties => ({
    fr: {
        name: "Français",
        dataType: "string",
        markdown: markdown,
        validation: { required, unique }
    },
    en: {
        name: "English",
        dataType: "string",
        markdown: markdown,
        validation: { required, unique },
    }
});

const productsCollection = buildCollection<Partial<Product>>({
    path: "products",
    name: "Products",
    group: "Main",
    permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true
    }),
    singularName: "Product",
    textSearchEnabled: true,
    callbacks: {
        onPreSave: ({
                        values,
                        previousValues
                    }: EntityOnSaveProps<Partial<Product>>) => {
            if (values.order !== previousValues?.order)
                values.shouldUpdateOrder = true;
            if (!values.slug) values.slug = { en: "", fr: "" };
            if (values.title?.en) values.slug.en = slugify(values.title?.en);
            if (values.title?.fr) values.slug.fr = slugify(values.title?.fr);
            return values;
        }
    },
    properties: {
        published: {
            name: "Published",
            dataType: "boolean",
            description: "Should this product be visible on the website.",
            defaultValue: true
        },
        order: {
            dataType: "number",
            name: "Order",
            defaultValue: 0
        },
        title: {
            name: "Title",
            dataType: "map",
            properties: translationProperties(true)
        },
        slug: {
            name: "Slug",
            dataType: "map",
            disabled: true,
            properties: translationProperties(false)
        },
        category: {
            name: "Category",
            dataType: "reference",
            path: "categories",
            validation: { required: true }
        },
        subcategory: ({ values }) => ({
            name: "Subcategory",
            dataType: "reference",
            forceFilter: { "category": ["==", values.category] },
            path: values.category ? "subcategories" : false
        }),
        images: ({ values, entityId }) => ({
            name: "Images",
            dataType: "array",
            description: "The dimensions must be 1200 X 706 pixels.",
            of: {
                dataType: "string",
                storage: {
                    mediaType: "image",
                    storagePath: "products/" + entityId,
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        }),
        teaser: {
            name: "Teaser",
            dataType: "map",
            properties: translationProperties(false)
        },
        description: {
            name: "Description",
            dataType: "map",
            properties: translationProperties(false, false, true)
        },
        title_youtube: {
            name: "Title Youtube",
            dataType: "map",
            properties: translationProperties(false)
        },
        youtube_id: {
            name: "Youtube ID",
            dataType: "string"
        },
        title_pdf: {
            name: "Title PDF",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                },
                en: {
                    name: "English",
                    dataType: "string",
                }
            }
        },
        pdf: ({ values, entityId }) => ({
            name: "PDF",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    storage: {
                        storagePath: "products/" + entityId + "/pdf",
                        acceptedFiles: ["application/pdf"]
                    }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    storage: {
                        storagePath: "products/" + entityId + "/pdf",
                        acceptedFiles: ["application/pdf"]
                    }
                }
            }
        }),
        printing_type: {
            name: "Printing types",
            dataType: "array",
            of: {
                dataType: "reference",
                path: "printing-types"
            }
        },
        specification_icons: {
            dataType: "array",
            name: "Specification icons",
            of: {
                dataType: "reference",
                path: "specification-icons"
            }
        },
        transport: {
            dataType: "array",
            name: "Transport products",
            of: {
                dataType: "reference",
                path: "products"
            }
        },
        portfolio: {
            dataType: "array",
            name: "Portfolio",
            of: {
                dataType: "reference",
                path: "portfolios"
            }
        },
        related_products: {
            dataType: "array",
            name: "Related products",
            description: "Maximum of 3 related products.",
            of: {
                dataType: "reference",
                path: "products"
            }
        },
        seo_title: {
            name: "SEO - Title",
            dataType: "map",
            description: "We recommend titles between 50 and 60 characters",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: {
                        max: 60
                    },
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: {
                        max: 60
                    },
                }
            }
        },
        seo_description: {
            name: "SEO - Description",
            dataType: "map",
            description: "We recommend descriptions between 50 and 160 characters",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    multiline: true,
                    validation: {
                        max: 160
                    },
                },
                en: {
                    name: "English",
                    dataType: "string",
                    multiline: true,
                    validation: {
                        max: 160
                    },
                }
            }
        },
    },
    subcollections: [configurationsCollection, modelsCollection, formatsCollection, accessoriesCollection]
});

export { productsCollection };
