import {
    buildCollection,
    buildProperty,
    EntityOnSaveProps
} from "@camberi/firecms";
import { Configuration, Model } from "../types/product_types";

const modelsCollection = buildCollection<Partial<Model>>({
    name: "Models",
    path: "models",
    defaultSize: "m",
    singularName: "Model",
    callbacks: {
        onPreSave: ({
                        values,
                        previousValues
                    }: EntityOnSaveProps<Partial<Model>>) => {
            if (values.order !== previousValues?.order)
                values.shouldUpdateOrder = true;
            return values;
        }
    },
    properties: {
        order: {
            dataType: "number",
            name: "Order",
            defaultValue: 0
        },
        title: {
            name: "Title",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
        description: {
            name: "Description",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    markdown: true
                },
                en: {
                    name: "English",
                    dataType: "string",
                    markdown: true
                }
            }
        },
        images: (entity) => buildProperty({
            name: "Images",
            dataType: "array",
            description: "The dimensions must be 452 x 340 pixels.",
            of: buildProperty({
                dataType: "string",
                storage: {
                    mediaType: "image",
                    storagePath: entity.path,
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            })
        })
    }
});

export { modelsCollection }
