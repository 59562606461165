import {
    buildCollection,
    buildProperty,
    EntityOnSaveProps
} from "@camberi/firecms";
import {
    Category,
    SpecificationIcon,
    Subcategory
} from "../types/system_types";
import { slugify } from "../tools";

const categoriesCollection = buildCollection<Partial<Category>>({
    path: "categories",
    name: "Categories",
    group: "System",
    singularName: "Category",
    callbacks: {
        onPreSave: ({
            values,
            previousValues
        }: EntityOnSaveProps<Partial<Category>>) => {
            if (values.order !== previousValues?.order)
                values.shouldUpdateOrder = true;
            if (!values.slug) values.slug = { en: "", fr: "" };
            if (values.name?.en) values.slug.en = slugify(values.name?.en);
            if (values.name?.fr) values.slug.fr = slugify(values.name?.fr);
            return values;
        }
    },
    properties: {
        order: {
            dataType: "number",
            name: "Order",
            defaultValue: 0
        },
        name: {
            name: "Category name",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
        slug: {
            name: "Slug",
            dataType: "map",
            disabled: true,
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
        image: buildProperty({
            title: "Image",
            dataType: "string",
            storage: {
                mediaType: "image",
                storagePath: "categories",
                acceptedFiles: ["image/*"]
            }
        }),
        seo_title: {
            name: "SEO - Title",
            dataType: "map",
            description: "We recommend titles between 50 and 60 characters",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: {
                        max: 60
                    },
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: {
                        max: 60
                    },
                }
            }
        },
        seo_description: {
            name: "SEO - Description",
            dataType: "map",
            description: "We recommend descriptions between 50 and 160 characters",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    multiline: true,
                    validation: {
                        max: 160
                    },
                },
                en: {
                    name: "English",
                    dataType: "string",
                    multiline: true,
                    validation: {
                        max: 160
                    },
                }
            }
        },
    },
    permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true
    })
});

const subcategoriesCollection = buildCollection<Partial<Subcategory>>({
    path: "subcategories",
    name: "Subcategories",
    group: "System",
    singularName: "Subcategory",
    callbacks: {
        onPreSave: ({
            values,
            previousValues
        }: EntityOnSaveProps<Partial<Subcategory>>) => {
            if (values.order !== previousValues?.order)
                values.shouldUpdateOrder = true;
            if (!values.slug) values.slug = { en: "", fr: "" };
            if (values.name?.en) values.slug.en = slugify(values.name?.en);
            if (values.name?.fr) values.slug.fr = slugify(values.name?.fr);
            return values;
        }
    },
    properties: {
        order: {
            dataType: "number",
            name: "Order",
            defaultValue: 0
        },
        category: {
            name: "Category",
            dataType: "reference",
            path: "categories",
            validation: { required: true }
        },
        name: {
            name: "Subcategory name",
            dataType: "map",
            disabled: true,
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true, unique: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true, unique: true }
                }
            }
        },
        slug: {
            name: "Slug",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
    },
    permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true
    })
});

const specificationIconsCollection = buildCollection<SpecificationIcon>({
    path: "specification-icons",
    name: "Specification icons",
    group: "System",
    singularName: "Specification icon",
    properties: {
        name: {
            name: "Specification icon name",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        },
        icon: buildProperty({
            name: "Icon",
            dataType: "string",
            storage: {
                mediaType: "image",
                storagePath: "spec-icons",
                acceptedFiles: ["image/*"]
            }
        }),
    },
    permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true
    })
});

const printingTypesCollection = buildCollection({
    path: "printing-types",
    name: "Printing types",
    singularName: "Printing type",
    properties: {
        name: {
            name: "Printing type name",
            dataType: "map",
            properties: {
                fr: {
                    name: "Français",
                    dataType: "string",
                    validation: { required: true }
                },
                en: {
                    name: "English",
                    dataType: "string",
                    validation: { required: true }
                }
            }
        }
    },
    group: "System"
});

export { categoriesCollection, subcategoriesCollection, specificationIconsCollection, printingTypesCollection }
